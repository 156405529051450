<template>
    <div>
        <v-select :getOptionLabel="getOptionLabel" :label="label" v-if="type !== 'multiple'" v-model="model" class="w-100 mr-0" ref="select" :options="options" :placeholder="text" :reduce="item => reduceField ? item[reduceField] : item" :disabled="disabled">
            <span slot="no-options" @click="$refs.select.open = false">
                Žal ni možnosti ujemanja
            </span>
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes"><FeatherIcon icon="ChevronDownIcon"/></span>
            </template>
        </v-select>

        <v-select :getOptionLabel="getOptionLabel" :label="label" v-if="type === 'multiple'" v-model="model" :on-change="cleanUpValidation" class="w-100 mr-0 multi" multiple ref="select" :selectable="selectableMultiple" :options="optionsMultiple" :placeholder="text" :reduce="item => reduceField ? item[reduceField] : item" :disabled="disabled">
            <span slot="no-options" @click="$refs.select.open = false">
                Žal ni možnosti ujemanja
            </span>
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes"><FeatherIcon icon="ChevronDownIcon"/></span>
            </template>
        </v-select>
    </div>
</template>

<script>
    import vSelect from 'vue-select'

    export default {
        components: {
            vSelect
        },
        props: {
            selectableMultiple:{
                type: Function,
                default: () => true
            },
            getOptionLabel: {
                type: Function,
                required: false
            },
            options: {
                type: Array,
                required: false
            },
            optionsMultiple: {
                type: Array,
                required: false
            },
            text: {
                type: String
            },
            type: {
                type: String,
                required: false
            },
            value: {
                default: null
            },
            valueMultiple: {
                type: Object
            },
            arrayValues: {
                type: Array,
                required: false
            },
            label: {
                type: String,
                required: false,
                default: 'label'
            },
            reduceField: {
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            cleanUpValidation(val) {
                this.arrayValues.splice(val)
            }
        },
        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            modelMultiple: {
                get() {
                    return this.valueMultiple
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>