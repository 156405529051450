var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{staticClass:"p-1 text-center",attrs:{"show":"","variant":"warning"}},[_vm._v(" "+_vm._s(this.type === 'edit' ? 'Po spremembi oglasa, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe oglasa na seznamu niso vidne, poskusite osvežiti spletno stran.' : 'Po oddaji oglasa, je potrebnih nekaj sekund, da je oglas prikazan na seznamu. Če oglasa na seznamu ne vidite, poskusite osvežiti spletno stran.')+" ")]),_c('b-form-group',{staticClass:"mt-1"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.active),callback:function ($$v) {_vm.$set(_vm.object, "active", $$v)},expression:"object.active"}},[_vm._v("Aktivno")])],1),_c('b-form-group',{attrs:{"label":"Tip"}},[_c('validation-provider',{attrs:{"name":"tip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',_vm._l((_vm.types),function(item,index){return _c('span',{key:index,staticClass:"ml-1"},[_c('b-form-radio',{attrs:{"name":"type","value":item.id},model:{value:(_vm.object.listing_type),callback:function ($$v) {_vm.$set(_vm.object, "listing_type", $$v)},expression:"object.listing_type"}},[_vm._v(_vm._s(item.title))])],1)}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Naslov"}},[_c('validation-provider',{attrs:{"name":"naslov","rules":"required|min:5|max:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Naslov"},model:{value:(_vm.object.title),callback:function ($$v) {_vm.$set(_vm.object, "title", $$v)},expression:"object.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Opis"}},[_c('validation-provider',{attrs:{"name":"opis","rules":"required|min:5|max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaEditor',{model:{value:(_vm.object.description),callback:function ($$v) {_vm.$set(_vm.object, "description", $$v)},expression:"object.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Cena"}},[_c('validation-provider',{attrs:{"name":"cena","rules":"required|max_value:10000000|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('b-input-group',{staticClass:"input-group-merge w-50",attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"step":"0.01","type":"number","value":_vm.price,"placeholder":"CENA"},on:{"input":_vm.changePrice}})],1)],1),_c('b-col',{staticClass:"text-left mt-1 mt-sm-0",attrs:{"cols":"12","sm":"4"}},[_c('b-form-radio',{attrs:{"name":"agreement","value":true},model:{value:(_vm.object.price_offer),callback:function ($$v) {_vm.$set(_vm.object, "price_offer", $$v)},expression:"object.price_offer"}},[_vm._v("PO DOGOVORU")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-checkbox',{staticClass:"mt-1",staticStyle:{"font-size":"16px"},attrs:{"disabled":_vm.object.price_offer,"switch":""},model:{value:(_vm.object.price_per),callback:function ($$v) {_vm.$set(_vm.object, "price_per", $$v)},expression:"object.price_per"}},[_vm._v("Cena na m"),_c('sup',[_vm._v("2")])])],1),_c('b-form-group',{attrs:{"label":"Lokacija"}},[_c('validation-provider',{attrs:{"name":"lokacija","rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Lokacija"},model:{value:(_vm.object.location),callback:function ($$v) {_vm.$set(_vm.object, "location", _vm._n($$v))},expression:"object.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.regions)?_c('b-form-group',{attrs:{"label":"Regija"}},[_c('validation-provider',{attrs:{"name":"regija","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"text":"Izberite regijo","options":_vm.regions,"label":"regija","getOptionLabel":_vm.getOptionToDisplayRegion},model:{value:(_vm.selectedRegion),callback:function ($$v) {_vm.selectedRegion=$$v},expression:"selectedRegion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3710693939)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Velikost"}},[_c('validation-provider',{attrs:{"name":"velikost","rules":"required|min_value:1|max_value:10000000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"step":"0.01","type":"number","placeholder":"Velikost"},model:{value:(_vm.object.size),callback:function ($$v) {_vm.$set(_vm.object, "size", _vm._n($$v))},expression:"object.size"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Leto izgradnje"}},[_c('validation-provider',{attrs:{"name":"Leto izgradnje","rules":"required|min_value:1800|max_value:2100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Leto izgradnje"},model:{value:(_vm.object.build),callback:function ($$v) {_vm.$set(_vm.object, "build", _vm._n($$v))},expression:"object.build"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Leto prenove"}},[_c('validation-provider',{attrs:{"name":"Leto prenove","rules":"min_value:1800|min_value:@Leto izgradnje"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Leto prenove"},model:{value:(_vm.object.renovated),callback:function ($$v) {_vm.$set(_vm.object, "renovated", _vm._n($$v))},expression:"object.renovated"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Etažnost"}},[_c('validation-provider',{attrs:{"name":"etažnost","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Etažnost"},model:{value:(_vm.object.floor),callback:function ($$v) {_vm.$set(_vm.object, "floor", $$v)},expression:"object.floor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.neighborhoods)?_c('b-form-group',{attrs:{"label":"Soseska"}},[_c('validation-provider',{attrs:{"name":"soseska"}},[_c('Select',{attrs:{"text":"Izberite sosesko","options":_vm.neighborhoods,"label":"soseska","getOptionLabel":_vm.getOptionToDisplayNeihgboorhood},model:{value:(_vm.selectedNeighborhood),callback:function ($$v) {_vm.selectedNeighborhood=$$v},expression:"selectedNeighborhood"}})],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Nepremičninski agent"}},[_c('Select',{attrs:{"text":"Izberite nepremičninskega agenta","options":_vm.agents,"label":"name","getOptionLabel":_vm.getOptionToDisplay},model:{value:(_vm.selectedAgent),callback:function ($$v) {_vm.selectedAgent=$$v},expression:"selectedAgent"}})],1),_c('b-form-group',{attrs:{"label":"Ponudnik"}},[_c('Select',{attrs:{"text":"Izberite ponudnika","options":_vm.$store.getters['user/managers']},model:{value:(_vm.object.provider),callback:function ($$v) {_vm.$set(_vm.object, "provider", $$v)},expression:"object.provider"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('ImageSelector',{ref:"imageSelector",attrs:{"main_photo":_vm.object.main_photo,"additional_photos":_vm.object.additional_photos,"type":_vm.type},on:{"update-main-photo":_vm.updateMainPhoto}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }