<template>
    <div>
        <b-alert class="p-1 text-center" show variant="warning">
            {{ this.type === 'edit' ? 
                'Po spremembi oglasa, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe oglasa na seznamu niso vidne, poskusite osvežiti spletno stran.' :
                'Po oddaji oglasa, je potrebnih nekaj sekund, da je oglas prikazan na seznamu. Če oglasa na seznamu ne vidite, poskusite osvežiti spletno stran.'
            }}
        </b-alert>
        <b-form-group class="mt-1">
            <b-form-checkbox switch v-model="object.active">Aktivno</b-form-checkbox>
        </b-form-group>
        <b-form-group label="Tip">
            <validation-provider #default="{ errors }" name="tip" rules="required">
                <b-row>
                    <span class="ml-1" :key="index" v-for="(item, index) in types">
                        <b-form-radio v-model="object.listing_type" name="type" :value="item.id">{{item.title}}</b-form-radio>
                    </span>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-form-group label="Naslov">
            <validation-provider #default="{ errors }" name="naslov" rules="required|min:5|max:120">
                <b-form-input id="name" v-model="object.title" placeholder="Naslov"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-row>
            <b-col cols='12' lg='6'>
                <b-form-group label="Opis">
                    <validation-provider #default="{ errors }" name="opis" rules="required|min:5|max:5000">
                        <TextAreaEditor v-model="object.description" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Cena">
                    <validation-provider #default="{ errors }" name="cena" rules="required|max_value:10000000|min_value:0">
                        <b-row align-v="center">
                            <b-col class="d-flex" cols="12" sm="6">
                                <b-input-group class="input-group-merge w-50" append="€">
                                    <b-form-input step="0.01" type="number" :value="price" v-on:input="changePrice" placeholder="CENA"/>
                                </b-input-group>
                            </b-col>
                            <b-col cols="12" sm="4" class="text-left mt-1 mt-sm-0">
                                <b-form-radio name="agreement" v-model="object.price_offer" :value="true">PO DOGOVORU</b-form-radio>
                            </b-col>
                        </b-row>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                    <b-form-checkbox :disabled="object.price_offer" class="mt-1" style="font-size: 16px" switch v-model="object.price_per">Cena na m<sup>2</sup></b-form-checkbox>
                </b-form-group>
                <b-form-group label="Lokacija">
                    <validation-provider #default="{ errors }" name="lokacija" rules="required|min:2|max:50">
                        <!--<Select v-model="object.location" :options="places" text="LOKACIJA"/>-->
                        <b-form-input v-model.number="object.location" placeholder="Lokacija"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Regija" v-if="regions"> 
                    <validation-provider #default="{ errors }" name="regija" rules="required">
                        <Select text="Izberite regijo" :options="regions" label="regija" v-model="selectedRegion" :getOptionLabel="getOptionToDisplayRegion"></Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Velikost">
                    <validation-provider #default="{ errors }" name="velikost" rules="required|min_value:1|max_value:10000000">
                        <b-form-input step="0.01" type="number" v-model.number="object.size" placeholder="Velikost"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Leto izgradnje">
                    <validation-provider #default="{ errors }" name="Leto izgradnje" rules="required|min_value:1800|max_value:2100">
                        <b-form-input type="number" v-model.number="object.build" placeholder="Leto izgradnje"/>
                        <small class="text-danger">
                            {{ errors[0] }}
                        </small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Leto prenove">
                    <validation-provider #default="{ errors }" name="Leto prenove" rules="min_value:1800|min_value:@Leto izgradnje">
                        <b-form-input type="number" v-model.number="object.renovated" placeholder="Leto prenove"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Etažnost">
                    <validation-provider #default="{ errors }" name="etažnost" rules="required|max:50">
                        <b-form-input v-model="object.floor" placeholder="Etažnost"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Soseska" v-if="neighborhoods"> 
                    <validation-provider name="soseska">
                        <Select text="Izberite sosesko" :options="neighborhoods" label="soseska" v-model="selectedNeighborhood" :getOptionLabel="getOptionToDisplayNeihgboorhood"></Select>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Nepremičninski agent">
                    <Select text="Izberite nepremičninskega agenta" :options="agents" label="name" v-model="selectedAgent" :getOptionLabel="getOptionToDisplay"></Select>
                    <!--                    <validation-provider #default="{ errors }" name="nepremičninski agent" rules="required">
                    <Select text="Izberite nepremičninskega agenta" :options="agents" label="name" v-model="selectedAgent" :getOptionLabel="getOptionToDisplay"></Select>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>-->
                </b-form-group>
                <b-form-group label="Ponudnik">
                    <Select text="Izberite ponudnika" :options="$store.getters['user/managers']" v-model="object.provider"></Select>
                    <!--                    <validation-provider #default="{ errors }" name="ponudnik" rules="required">
                        <Select text="Izberite ponudnika" :options="$store.getters['user/managers']" v-model="object.provider"></Select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>-->
                </b-form-group>
            </b-col>
            <b-col cols='12' lg='6'>
                <ImageSelector ref="imageSelector" :main_photo="object.main_photo" :additional_photos="object.additional_photos" @update-main-photo="updateMainPhoto" :type="type"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'
    import { required } from '@validations'
    import TextAreaEditor from '../Components/TextAreaEditor'
    import ImageSelector from '../Components/ImageSelector'
    import Select from '@/views/Components/Select.vue'
    import {BRow, BCol, BFormGroup, BFormInput, BFormRadio, BFormCheckbox, BInputGroup, BAlert} from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            TextAreaEditor,
            ImageSelector,
            Select,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormRadio,
            BFormCheckbox,
            BInputGroup,
            BAlert
        },
        model: {
            prop: 'model',
            event: 'input'
        },
        props: {
            model: {
                type: Object,
                default: null
            },
            value: {
                default: null
            },
            type: {
                type: String
            }
        },
        data() {
            return {
                regions:[
                    {title: 'Osrednjeslovenska', value: {region: 1}},
                    {title: 'Gorenjska', value:  {region: 2}},
                    {title: 'Goriška', value: {region: 3}},
                    {title: 'Obalno - kraška', value: {region: 4}},
                    {title: 'Primorsko - notranjska', value: {region: 5}},
                    {title: 'Jugovzhodna', value: {region: 6}},
                    {title: 'Posavska', value: {region: 7}},
                    {title: 'Zasavska', value: {region: 8}},
                    {title: 'Savinjska', value: {region: 9}},
                    {title: 'Koroška', value: {region: 10}},
                    {title: 'Podravska', value: {region: 11}},
                    {title: 'Pomurska', value: {region: 12}}
                ],
                types: [
                    {id:0, title: 'PRODAJA'},
                    {id:1, title: 'ODDAJA'},
                    {id:2, title: 'NAJEM'},
                    {id:3, title: 'ZAMENJAVA'}
                ],
                required,
                agents: [],
                selectedAgent: null,
                created: false,
                price: 0,
                neighborhoods: null,
                selectedNeighborhood: null,
                selectedRegion: null
            }
        },
        methods: {
            async getNeighborhoods() {

                try {
                    const res = await this.$http.get('/api/management/v1/neighborhoods')
                    this.neighborhoods = res.data
                    for (const item of this.neighborhoods) {
                        if (item.id === this.object.neighborhood_id) {
                            this.selectedNeighborhood = item
                        }
                    }
                } catch (err) {
                    this.$printError('Prislo je do napake')
                }
            },
            changePrice(val) {
                this.price = val
                this.object.price = Number(Number(val * 100).toFixed(0))
            },
            getOptionToDisplayRegion(region) {
                if (!region) return 'Napaka pri prikazu'
                return `${region.title}`
            },
            getOptionToDisplay(agent) {
                if (!agent) return 'Napaka pri prikazu'
                return `${agent.name} ${agent.surname} - (${agent.email}, ${agent.telephone_number})`
            },
            getOptionToDisplayNeihgboorhood(neighborhood) {
                if (!neighborhood) return 'Napaka pri prikazu'
                return `${neighborhood.title}`
            },
            updateMainPhoto(photo) {
                this.object.main_photo = photo
            },
            async getRealEstateAgents() {
                this.showLoader = true
                try {
                    const response = await this.$http.get('/api/management/v1/real_estate/agent/')
                    this.agents = response.data ?? []

                    if (this.type === 'edit') {
                        const agentIndex = this.agents.findIndex(a => a.id === this.object.agent.id)
                        if (agentIndex !== -1) this.selectedAgent = this.agents[agentIndex]
                    }
                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            },
            setPrice() {
                if (this.type === 'edit') this.price = this.object.price / 100
                else this.price = 0
            }
        },
        computed: {
            object: {
                get() {
                    return this.model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            'price'(val) {
                if (val > 0) this.object.price_offer = false
                else if (val === 0) this.object.price_offer = true
            },
            'object.price_offer'(val) {
                if (val) {
                    this.price = 0
                    this.object.price = 0
                    this.object.price_per = false
                }
            },
            selectedAgent() {
                if (!this.selectedAgent) this.object.agent_id = ''  //return
                else this.object.agent_id = this.selectedAgent.id
            },
            selectedNeighborhood() {
                if (!this.selectedNeighborhood) {
                    this.object.neighborhood_id = '000000000000000000000000'
                    return
                } 
                this.object.neighborhood_id = this.selectedNeighborhood.id
            },
            selectedRegion() {
                if (!this.selectedRegion) {
                    this.object.region = -1
                    return
                } 
                this.object.region = this.selectedRegion.value.region
            }
        },
        async beforeMount() {
            await this.getRealEstateAgents()
            await this.getNeighborhoods()
            await this.setPrice()

            if (this.object.region) {
                for (const item of this.regions) {
                    if (item.value.region === this.object.region) {
                        this.selectedRegion = item
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
